import React, { useState, useEffect } from "react";
import { createFilter } from "react-select";
import { baseUrl, elevatorCompany } from "core/UrlEndpoints";
import { fetchDataJson } from "core/FetchApi";
import ElevatorCompanyService from "core/api/ElevatorCompanyService";
import AsyncSelect from "react-select/async";
import { Label } from "reactstrap";

export const CompanySelectorInputComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const filterCompanies = async (inputValue) => {
    const responseData = await fetchDataJson(
      `${baseUrl}/${elevatorCompany}?size=${10}&page=${1}&filter[Name]=${inputValue}*`,
      {
        method: "GET",
      }
    );

    if (responseData.model.length === 0) return null;

    return responseData.model.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(async () => {
      var items = await filterCompanies(inputValue);
      callback(items);
    }, 1000);
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
  };

  useEffect(() => {
    async function fetchData() {
      const elevatorCompany = await ElevatorCompanyService.get(field.value)
      setSelected({ value: field.value, label: elevatorCompany.name })
    }
    if (field.value) {
      fetchData();
    }
  }, [])


  return (
    <div>
      <Label>{props.label}</Label>
      <AsyncSelect
        value={selected}
        filterOption={createFilter(filterConfig)}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        {...props}
        onChange={(option) => {
          setFieldValue(field.name, option?.value);
          setSelected(option ? { value: option.value, label: option.label } : null)
        }}
        getOptionLabel={(option) => `${option.label}`}
        isClearable
      />
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </div>
  );
};
