import React from "react";
import { useAuth0 } from "react-auth0-spa";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import UserLogService from "core/api/UserLogService";

const ProfileButton = () => {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

    const logoutUser = async () => {
        const userLog = { UserEmail: user.email, Status: "Log Out", LogDate: new Date() }
        await UserLogService.add(userLog)
    }

    return (
        <UncontrolledDropdown group>
            <DropdownToggle caret>
                <i className="now-ui-icons users_single-02" />
                <p>
                    <span className="d-lg-none d-md-block">Account</span>
                </p>
            </DropdownToggle>
            <DropdownMenu>
                {isAuthenticated && <DropdownItem onClick={() => { logoutUser(); logout() }}>Log out</DropdownItem>}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ProfileButton;