import React from "react";
import { Field } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import * as Yup from "yup";
import { Row, Col } from "reactstrap";
import CrudEdit from "../Crud/Edit";
import customerService from "core/api/CustomerService.js";
import Address from "../Shared/Address";

const CustomerSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  line2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string()
    .length(2, "Invalid state")
    .uppercase("Must be uppercase")
    .required("Required"),
  postalCode: Yup.string().required("Required"),
  country: Yup.string()
    .length(3, "3 letter country initials")
    .required("Required"),
  phone: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const Edit = () => {
  const initialData = {
    id: null,
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
    sendAlerts: false,
  };

  return (
    <>
      <CrudEdit
        title="Customer"
        initData={initialData}
        crudService={customerService}
        validationSchema={CustomerSchema}>
        <Row>
          <Col>
            <Field
              type="text"
              name="company"
              label="Company Name"
              component={ReactstrapInput}
            />
          </Col>
          <Col>
            <Field
              type="text"
              name="name"
              label="Contact Name"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Address component={ReactstrapInput} />
        <Row>
          <Col>
            <Field
              type="text"
              name="phone"
              label="Phone Number"
              component={ReactstrapInput}
            />
          </Col>
          <Col>
            <Field
              type="email"
              name="email"
              label="Email Address"
              component={ReactstrapInput}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              <Field type="checkbox" name="sendAlerts" />
              Send Alerts
            </label>
          </Col>
        </Row>
      </CrudEdit>
    </>
  );
};

export default Edit;
