const baseUrl = process.env.REACT_APP_BASE_URL;
const customer = "customer";
const building = "building";
const elevator = "elevator";
const device = "device";
const alert = "alert";
const dashboard = "dashboard";
const fileBrowser = "filebrowser";
const filesBucket = "eletechimages";
const call = "call";
const userLog = "userlog";
const elevatorCompany = "elevatorCompany";
const notifyContacts = "notifyContacts"

export {
  baseUrl,
  customer,
  building,
  elevator,
  device,
  alert,
  dashboard,
  fileBrowser,
  filesBucket,
  call,
  userLog,
  elevatorCompany,
  notifyContacts
};
