import React from "react";
import {
    Row,
    Col
} from "reactstrap";
import HeaderInfoItem from "./HeaderInfoItem";

const HeaderAddress = ({ line1, line2, city, state, postalCode, country }) => {
    return <>
        <Row>
            <Col>
                <HeaderInfoItem title="Address">{line1}</HeaderInfoItem>
                <HeaderInfoItem>{line2}</HeaderInfoItem>
            </Col>
        </Row>
        <Row>
            <Col>
                <HeaderInfoItem title="City">{city}</HeaderInfoItem>
            </Col>
            <Col>
                <HeaderInfoItem title="State">{state}</HeaderInfoItem>
            </Col>
            <Col>
                <HeaderInfoItem title="Zip Code">{postalCode}</HeaderInfoItem>
            </Col>
        </Row>
    </>;
}

export default HeaderAddress