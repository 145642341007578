import React, { useCallback, useState, useEffect } from "react"
import { Model, ComputedUpdater } from 'survey-core'
import { Survey } from "survey-react-ui";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useAuth0 } from "react-auth0-spa";
import { PlainLight } from "survey-core/themes/plain-light";
import CallService from "core/api/CallService";
import BuildingService from "core/api/BuildingService";
import NotifyContactsService from "core/api/NotifyContactsService";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Button, Card, CardBody, CardHeader, CardTitle, Row, Col, Label, } from "reactstrap"
import ElevatorCompanyService from "core/api/ElevatorCompanyService";
import { useHistory } from "react-router-dom";


export default function ElevatorForm() {
    const [reportData, setReportData] = useState();
    const [buildingData, setBuildingData] = useState({});
    const [survey, setSurvey] = useState();
    const { user } = useAuth0();
    const { id } = useParams();
    const [reportSaved, setReportSaved] = useState(false);
    const [terminateReportPopup, setTerminateReportPopup] = useState(false);
    const [terminationReason, setTerminationReason] = useState("")
    const history = useHistory();

    const surveyJson = {
        clearInvisibleValues: "onHidden",
        completeText: "Send Report",
        completedHtml: "<b><h2>Your Report Was Submitted</h2></b>",
        requiredText: "",
        showQuestionNumbers: false,
        showTimerPanel: "bottom",
        showTimerPanelMode: "survey", 
        pages: [
            {
                elements: [
                    {
                        name: "responseFromPerson",
                        title: "Did you get a response from the person in the elevator?",
                        type: "radiogroup",
                        choices: ["Yes", "No"],
                        isRequired: true
                    },
                    {
                        name: "videoAvailable",
                        title: "Is video available?",
                        type: "radiogroup",
                        choices: ["Yes", "No"],
                        visibleIf: "{responseFromPerson} = 'No'",
                        isRequired: true
                    },
                    {
                        name: "checkVideo",
                        title: "What do you see?",
                        type: "dropdown",
                        choices: ["Call empty", "Person has hearing disability"],
                        visibleIf: "{videoAvailable} = 'Yes'",
                        isRequired: true
                    },
                    {
                        name: "communicateByText",
                        title: "Communicate with person who has hearing disability",
                        type: "checkbox",
                        choices: [{ value: "Yes", text: "Text person to communicate" }],
                        visibleIf: "{checkVideo} = 'Person has hearing disability'",
                        isRequired: true
                    },
                    {
                        name: "helpNeeded",
                        title: "Is help needed?",
                        type: "radiogroup",
                        choices: ["Yes", "No"],
                        visibleIf: "{responseFromPerson} = 'Yes' or {communicateByText} contains 'Yes' ",
                        isRequired: true
                    }
                ]
            },
            {
                name: "DispatchPage",
                elements: [
                    {
                        name: "dispatch",
                        title: "What is wrong?",
                        type: "dropdown",
                        choices: ["Person stuck in elevator", "Medical Emergency"],
                        visibleIf: "{helpNeeded} = 'Yes' or {videoAvailable} = 'No'",
                        isRequired: true
                    },
                ]
            },
            {
                name: "CommentsPage",
                elements: [
                    {
                        name: "notes",
                        title: "Additional Notes",
                        type: "comment"
                    }
                ]
            }
        ]


    }

    const saveReport = async (sender, incomplete = false, reportInfo, terminate = false) => {
        let data = reportData ? reportData : reportInfo

        let timeSpent = sender.timeSpent < 60 ? 1 : Math.trunc(sender.timeSpent / 60) 
        const surveyQuestions = sender.getAllQuestions(true)

        data = {
            ...data,
            callLength: timeSpent,
            surveyResults: sender.data,
        }

        if (terminate) {
            data = {
                ...data,
                surveyResults: { ...data.surveyResults, terminationReason: terminationReason },
                dateTimeSubmitted: new Date(), status: "terminated",
            }
        }

        if (!incomplete) {
            const lastQuestionBeforeNotes = surveyQuestions.at(surveyQuestions.length - 2)

            var resolution
            switch (lastQuestionBeforeNotes.name) {
                case "helpNeeded":
                    resolution = "No Help Needed"
                    break;
                case "checkVideo":
                    resolution = lastQuestionBeforeNotes.value
                    break;
                default:
                    if (lastQuestionBeforeNotes.name.toLowerCase().includes("confirmation") && lastQuestionBeforeNotes.value == "Yes") {
                        resolution = surveyQuestions.at(surveyQuestions.length - 3).title
                    } else {
                        resolution = "No Resolution"
                    }
            }

            data = {
                ...data,
                dateTimeSubmitted: new Date(), status: "submitted",
                resolution: resolution,
                surveyResults: { ...data.surveyResults, dispatchQuestions: sender.toJSON().pages.find(page => page.name == "DispatchPage").elements }
            }
        }

        const call = await CallService.add(data);

        if (data.status == "submitted" || data.status == "terminated") {
            if (surveyQuestions.find(q => q.name == "call911")?.value == "Yes") {
                await NotifyContactsService.notifyContactsThat911Called(call)
            }
        }
    };

    const terminateReport = () => {
        saveReport(survey, true, null, true)
        history.push(`../callcenter`)
        //window.close('', '_parent', '');
    }

    useEffect(() => {
        async function fetchData() {
            var callData = await CallService.get(id);
            const buildingData = await BuildingService.get(callData.buildingId);
            const elevatorCompanyData = await ElevatorCompanyService.get(buildingData.elevatorCompanyId)

            setReportData(callData)
            setBuildingData(buildingData)

            var availableContacts = []

            if (callData.status == "answered") {

                buildingData?.contacts.forEach((contact) => {
                    if (contact.availableForEmergencyCalls) {
                        availableContacts.push({ ...contact, type: "Building" })
                    }
                })

                elevatorCompanyData?.contacts.forEach((contact) => {
                    if (contact.availableForEmergencyCalls) {
                        availableContacts.push({ ...contact, type: "Elevator Company" })
                    }
                })
            }

            setUpForm(callData, availableContacts, buildingData.dispatchTo911)
        }
        fetchData();
    }, [id]);



    const setUpForm = (data, availableContacts, call911) => {
        const survey = new Model(surveyJson)

        if (data.status == "answered") {
            survey.mode = "edit"

            survey.addNavigationItem({
                id: "sj-save-incomplete-survey",
                title: "Save Incomplete Form",
                action: () => { saveReport(survey, true, data); setReportSaved(true) },
                css: "nav-button",
                innerCss: "sd-btn nav-input",
                visibleIndex: 50,
                visible: new ComputedUpdater((() => { return survey.currentPage.name != "CommentsPage" }))
            });

            survey.addNavigationItem({
                id: "sj-save-incomplete-survey",
                title: "Terminate Call",
                action: () => { setTerminateReportPopup(true) },
                css: "nav-button",
                innerCss: "sd-btn nav-input",
                visibleIndex: 52,
                visible: new ComputedUpdater((() => { return survey.currentPage.name != "CommentsPage" }))
            });

            for (let i = 0; i < availableContacts.length; i++) {
                const data = availableContacts[i]

                const page = survey.getPageByName("DispatchPage")
                const newQuestion = page.addNewQuestion("checkbox", `callContact${i + 1}`)
                const responseQuestion = page.addNewQuestion("radiogroup", `helpConfirmation${i + 1}`)

                newQuestion.title = `Call ${data.type} Contact`
                newQuestion.choices = [{ value: "Yes", text: `Call ${data.contactName ? data.contactName : data.type + " Contact"} at ${data.phone}` }]
                newQuestion.isRequired = true
                newQuestion.visibleIf = i == 0 ? "{dispatch} = 'Person stuck in elevator'" : `{helpConfirmation${i}} = 'No'`
                newQuestion.hasComment = true
                newQuestion.commentText = "Call Notes"

                responseQuestion.title = "Did you get a confirmation that help is on the way"
                responseQuestion.choices = ["Yes", "No"]
                responseQuestion.isRequired = true
                responseQuestion.visibleIf = `{callContact${i + 1}} contains 'Yes'`
            }


            const questions = survey.getPageByName("DispatchPage").getQuestions()
            const lastQuestion = questions.at(questions.length - 1).name

            const call911Question = survey.getPageByName("DispatchPage").addNewQuestion("checkbox", "call911")
            call911Question.title = "Dispatch to 911"
            call911Question.choices = [{ value: "Yes", text: "Call 911" }]
            call911Question.isRequired = true
            call911Question.visibleIf = `{dispatch} = 'Medical Emergency' ${call911 && (lastQuestion.includes("helpConfirmation")) ? `or {${lastQuestion}} = 'No'` : ""}
                ${call911 && availableContacts.length == 0 ? "or {dispatch} = 'Person stuck in elevator'" : ""} `
            call911Question.hasComment = true
            call911Question.commentText = "Call Notes"

            const call911Confirmation = survey.getPageByName("DispatchPage").addNewQuestion("radiogroup", "911Confirmation")
            call911Confirmation.title = "Did you get a confirmation that help is on the way"
            call911Confirmation.choices = ["Yes", "No"]
            call911Confirmation.isRequired = true
            call911Confirmation.visibleIf = "{call911} contains 'Yes' "
        } else {
            survey.mode = "display"

            if (data?.surveyResults?.dispatchQuestions) {
                surveyJson.pages.find(page => page.name == "DispatchPage").elements = data?.surveyResults?.dispatchQuestions
                survey.fromJSON(surveyJson)
            }
        }

        survey.data = data.surveyResults ? data.surveyResults : {}

        survey.applyTheme(PlainLight);

        survey.timeSpent = data.callLength * 60

        setSurvey(survey)
    }


    return (
        <>
            <PanelHeader content={
                <div className="header text-center">
                    <h1 className="title">
                        Elevator Report
                    </h1>
                    {reportData && 
                        <h2 className="title">
                            {reportData.elevatorPhoneNumber} - {reportData.elevatorName}
                        </h2>
                    }

                </div>
            } />


            <Modal isOpen={reportSaved} centered={true} >
                <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                    <div className="title">Form was Saved</div>
                    <i className="las la-clipboard-check" style={{ fontSize: 60 }}></i>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setReportSaved(false)} style={{ margin: "auto", display: "block" }} >OK</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={terminateReportPopup} centered={true} >
                <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                    <div className="title">Terminate Report</div>
                    <i className="las la-phone-slash" style={{ fontSize: 60 }}></i>
                    <div>Enter termination reason</div>
                    <textarea onChange={(e) => setTerminationReason(e.target.value)} style={{ width: "65%" }}></textarea>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setTerminateReportPopup(false)} style={{ margin: "auto", display: "block" }} >Cancel</Button>
                    <Button color="primary" onClick={() => terminateReport()} style={{ margin: "auto", display: "block" }} >Terminate</Button>
                </ModalFooter>
            </Modal>

            <div className="content">
                <Row>
                    <Col >
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4" style={{ marginTop: 0, fontWeight: "bold" }}>Dispatch Notes</CardTitle>
                            </CardHeader>
                            <CardBody style={{ paddingTop: 0 }}>
                                <Label style={{ fontSize: 16, }}>
                                    {buildingData.dispatchNotes ?? "No notes"}
                                </Label>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {survey && <Survey model={survey} onComplete={() => saveReport(survey)} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div >
        </>
    )
}