import React, { useState } from "react";
import { Field, FieldArray } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import * as Yup from "yup";
import { Row, Col, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CrudEdit from "../Crud/Edit";
import ElevatorCompanyService from "core/api/ElevatorCompanyService";
import Address from "../Shared/Address"
import Contact from "../Shared/Contact"
import { useParams, useHistory } from "react-router-dom";

const ElevatorCompanySchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  line2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string()
    .length(2, "Invalid state")
    .uppercase("Must be uppercase")
    .required("Required"),
  postalCode: Yup.string().required("Required"),
  country: Yup.string()
    .length(3, "3 letter country initials")
    .required("Required"),
  contacts: Yup.array().of(
    Yup.object().shape({
      contactName: Yup.string().required("Required"), phone: Yup.string().required("Required"), email: Yup.string().email("invalid email")
    })
  )
});

const Edit = () => {
  let { id } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();

  const initialData = {
    id: null,
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    contacts: [{
      contactName: "",
      phone: "",
      email: "",
      availableForEmergencyCalls: false,
      sendAlerts: false,
      notifyWhen911Called: false,
      defaultContact: true
    }],

  };

  const deleteCompany = async () => {
    await ElevatorCompanyService.delete(id)
    history.goBack()
  }


  return (
    <>
      {showAlert && (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => {
            deleteCompany();
          }}
          onCancel={() => setShowAlert(false)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel>
          Are your sure you want to delete this item?
        </SweetAlert>
      )}

      <CrudEdit
        title="Elevator Company"
        initData={initialData}
        crudService={ElevatorCompanyService}
        validationSchema={ElevatorCompanySchema}>
        {id ?
          <Row>
            <Col>
              <Button color="danger" onClick={() => setShowAlert(true)}>Delete this company</Button>
            </Col>
          </Row>
          : <></>}
        <Row>
          <Col>
            <Field
              type="text"
              name="name"
              label="Company Name"
              component={ReactstrapInput}
            />
          </Col>
        </Row>

        <Address component={ReactstrapInput} />

        <Row>
          <FieldArray name="contacts">
            {({ form: { values, setFieldValue }, push, remove }) => {
              return (
                <div>
                  {values.contacts.map((contact, index) => (
                    <div key={index}>
                      <Contact component={ReactstrapInput} fieldNamePrefix={`contacts.${index}.`} remove={() => remove(index)}
                        setDefaultContact={(value) => {
                          if (value) { values.contacts.forEach((contact, i) => { setFieldValue(`contacts.${i}.defaultContact`, false) }); }
                          setFieldValue(`contacts.${index}.defaultContact`, value);
                        }} />
                    </div>

                  ))}

                  <Button color="info" onClick={() => {
                    push({ contactName: "", phone: "", email: "", availableForEmergencyCalls: false, sendAlerts: false, notifyWhen911Called: false, defaultContact: false });
                  }} >Add Contact</Button>
                </div>)
            }}
          </FieldArray>
        </Row>

      </CrudEdit>
    </>
  );
};

export default Edit;
