import { fetchData, fetchDataJson } from "../FetchApi";
import { fileBrowser } from "core/UrlEndpoints";

class FileBrowserService {
  constructor() {
    this.endpont = fileBrowser;
  }

  async getList({ key }) {
    return fetchDataJson(`${this.endpont}?key=${key}`);
  }

  async rename(id, fileName, newFileName) {
    return fetchData(`${this.endpont}/rename?key=${id}`, {
      method: "POST",
      body: JSON.stringify({
        fileName,
        newFileName,
      }),
    });
  }

  async delete(id) {
    return fetchData(`${this.endpont}?fileKey=${id}`, {
      method: "DELETE",
    });
  }
}

export default new FileBrowserService();
