import React from "react";
import Table from "../Shared/Table";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { customer } from "core/UrlEndpoints";
import { useHistory } from "react-router-dom";

const CustomerList = () => {
  const history = useHistory();

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Customers</h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Customers</CardTitle>
                <Button
                  color="primary"
                  onClick={() => history.push(`customers/add`)}>
                  Add
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  url={customer}
                  columns={[
                    {
                      header: "Company",
                      accessorKey: "company",
                    },
                    {
                      header: "Name",
                      accessorKey: "name",
                    },
                    {
                      header: "Address",
                      accessorKey: "line1",
                      cell: ({ row }) => (
                        <>
                          {row.original.line1} {row.original.line2},{" "}
                          {row.original.city} {row.original.state}
                        </>
                      ),
                    },
                    {
                      header: "Phone",
                      accessorKey: "phone",
                    },
                    {
                      header: "Email",
                      accessorKey: "email",
                    },
                    {
                      header: "Send Alerts",
                      accessorKey: "sendAlerts",
                      cell: ({ row }) => (
                        <>{row.original.sendAlerts ? "True" : ""}</>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomerList;
