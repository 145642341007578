import { elevator } from "core/UrlEndpoints";
import CrudService from "./CrudService";

class ElevatorService extends CrudService {
  constructor() {
    super(elevator);
  }
}

export default new ElevatorService();
