import { customer } from "core/UrlEndpoints";
import CrudService from "./CrudService";

class CustomerService extends CrudService {
  constructor() {
    super(customer);
  }
}

export default new CustomerService();
