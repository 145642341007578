/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Table from "../Shared/Table";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  CardLink,
  Button,
  CardText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { alert as alertEndpoint } from "core/UrlEndpoints";
import customerService from "core/api/CustomerService";
import buildingService from "core/api/BuildingService";
import elevatorService from "core/api/ElevatorService";
import deviceService from "core/api/DeviceService";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import HeaderAddress from "../Shared/HeaderAddress";
import Moment from "react-moment";
import { CrudDropdownEditDelete } from "../Shared/CrudDropdownEditDelete";
import { customer } from "core/UrlEndpoints";

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: "",
    customerId: "",
    buildingId: "",
    name: "",
    phoneNumber: "",
  });

  const [buildingData, setBuildingData] = useState({
    id: "",
    customerId: "",
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  const [customerData, setCustomerData] = useState({
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });
  const [defaultContact, setDefaultContact] = useState()

  useEffect(() => {
    async function fetchData() {
      const deviceInfo = await elevatorService.get(id);
      const buildingInfo = await buildingService.get(deviceInfo.buildingId);
      const customerInfo = await customerService.get(deviceInfo.customerId);

      setData(deviceInfo);
      setBuildingData(buildingInfo ?? {});
      setCustomerData(customerInfo ?? {});
      setDefaultContact(buildingInfo.contacts.find(contact => contact.defaultContact))
      setLoading(false);
    }
    fetchData();
  }, [id]);

  async function MakeTestCall() {
    await deviceService.makeTestCall({ id });
    alert("Test Call Started");
  }

  if (loading) return <>Loading</>;

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h1 className="title">
              {data.name} - {data.phoneNumber}
            </h1>
            <h2 className="title">
              {customerData.company} / {buildingData.buildingName}
            </h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                {loading && <>loading</>}
                {loading || (
                  <Row>
                    <Col style={{ border: "1px" }}>
                      <HeaderInfoItem title="Company">
                        {customerData.company}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Contact Name">
                        {customerData.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Building">
                        {buildingData.buildingName}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Building Contact Name">
                        {defaultContact.contactName}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Phone Number">
                        {defaultContact.phone}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Email Address">
                        {defaultContact.email}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderAddress {...buildingData} />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h4">Information</CardTitle>
                <Col xs="auto">
                      <CrudDropdownEditDelete
                        id={id}
                        onDelete={() => {
                          elevatorService.delete(id);
                        }}
                      />
                </Col>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem>
                    Elevator Id: {data.serialNumber}
                  </ListGroupItem>
                  <ListGroupItem>
                    Phone Number: {data.phoneNumber}
                  </ListGroupItem>
                  <ListGroupItem>
                    Is Billing: {data.isBilling ? "True" : "False"}
                  </ListGroupItem>
                  <ListGroupItem>
                    Installation Date: {data.installationDate}
                  </ListGroupItem>
                  <ListGroupItem>
                    Device Location: {data.location}
                  </ListGroupItem>
                  <ListGroupItem>
                    Phone Board S/N: {data.boardSerialNumber}
                  </ListGroupItem>
                  <ListGroupItem>
                    Motor Room Location: {data.motorRoomLocation}
                  </ListGroupItem>
                  <ListGroupItem>
                    Call Pause Length: {data.callPauseLength}
                  </ListGroupItem>
                  <ListGroupItem>
                    Device Type: {data.deviceType ?? "Elevator"}
                  </ListGroupItem>
                  <ListGroupItem>
                    Only Line Test: {data.onlyLineTest ? "True" : "False"}
                  </ListGroupItem>
                  <ListGroupItem>
                    Don't call phone: {data.doNotCall ? "True" : "False"}
                  </ListGroupItem>
                  <ListGroupItem>
                    Don't check online status:{" "}
                    {data.doNotCheckOnlineStatus ? "True" : "False"}
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle tag="h4">Alerts</CardTitle>
                <Button
                  variant="primary"
                  onClick={async () => await MakeTestCall()}>
                  Make Test Call
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  pathroot="../alerts"
                  url={alertEndpoint}
                  filters={{ DeviceId: id }}
                  orderBy={{ field: "DateTime", desc: true }}
                  columns={[
                    {
                      header: "App Name",
                      accessorKey: "appName",
                    },
                    {
                      header: "Status",
                      accessorKey: "status",
                      cell: ({ row }) => (
                        <>{row.original.status == 1 ? "Working" : "Failed"}</>
                      ),
                    },
                    {
                      header: "Date",
                      accessorKey: "dateTime",
                      cell: ({ row }) => (
                        <Moment>{row.original.dateTime}</Moment>
                      ),
                    },
                    {
                      header: "Call Status",
                      accessorKey: "callStatus",
                    },
                    {
                      header: "Response Code",
                      accessorKey: "responseCode",
                    },
                    {
                      header: "Online Status",
                      accessorKey: "onlineStatus",
                    },
                    {
                      header: "Is Test",
                      accessorKey: "isTestCall",
                      cell: ({ row }) => (
                        <>{row.original.isTestCall ? "True" : ""}</>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default View;
