/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Table from "../Shared/Table";
import Gallery from "./Gallery";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
} from "reactstrap";

import Moment from "react-moment";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { elevator } from "core/UrlEndpoints";
import customerService from "core/api/CustomerService";
import buildingService from "core/api/BuildingService";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import HeaderAddress from "../Shared/HeaderAddress";
import { CrudDropdownEditDelete } from "../Shared/CrudDropdownEditDelete";

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    customerId: "",
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });
  const [customerData, setCustomerData] = useState({
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });
  const [defaultContact, setDefaultContact] = useState()

  useEffect(() => {
    async function fetchData() {
      const buildingInfo = await buildingService.get(id);
      setData(buildingInfo);
      setCustomerData(await customerService.get(buildingInfo.customerId));
      setDefaultContact(buildingInfo.contacts.find(contact => contact.defaultContact)) 
      setLoading(false);
    }
    fetchData();
  }, [id]);

  if (loading) return <>Loading</>;

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">
              {customerData.company} / {data.buildingName}
            </h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                {loading && <>loading</>}
                {loading || (
                  <Row>
                    <Col style={{ border: "1px" }}>
                      <HeaderInfoItem title="Company">
                        {customerData.company}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Contact Name">
                        {customerData.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Building">
                        {data.buildingName}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Building Contact Name">
                        {defaultContact.contactName} 
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Phone Number">
                        {defaultContact.phone}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Email Address">
                        {defaultContact.email}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderAddress {...data} />
                    </Col>
                    <Col xs="auto">
                      <CrudDropdownEditDelete
                        id={id}
                        onDelete={() => {
                          buildingService.delete(id);
                          history.goBack();
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Elevators</CardTitle>
                <Button
                  color="primary"
                  onClick={() =>
                    history.push(`../elevators/${data.customerId}|${id}/add`)
                  }>
                  Add
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  pathroot="../elevators"
                  url={elevator}
                  filters={{ BuildingId: id }}
                  columns={[
                    {
                      header: "Name",
                      accessorKey: "name",
                    },
                    { header: "Phone", accessorKey: "phoneNumber" },
                    {
                      header: "Is Billing",
                      accessorKey: "isBilling",
                      cell: ({ row }) => (
                        <>{row.original.isBilling ? "True" : "False"}</>
                      ),
                    },
                    {
                      header: "Last Status",
                      accessorKey: "LastStatus",
                    },
                    {
                      header: "Last Update Note",
                      accessorKey: "lastStatusNote",
                    },
                    {
                      header: "Last Status Updated",
                      accessorKey: "lastStatusUpdated",
                      cell: ({ row }) => (
                        <>
                          {row.original.lastStatusUpdated == null ? (
                            ""
                          ) : (
                            <Moment>{row.original.lastStatusUpdated}</Moment>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Images</CardTitle>
              </CardHeader>
              <CardBody>
                <Gallery id={`buildings/${data.id}`} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default View;
