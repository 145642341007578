import { call } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchDataJson } from "../FetchApi";

class CallService extends CrudService {
  constructor() {
    super(call);
  }

  async getIncomingCallsForUser(userEmail) {
    return fetchDataJson(`${call}/GetIncomingCallsForUser?userEmail=${userEmail}`, {
      method: "GET",
    });
  }

}

export default new CallService();
