import { dashboard } from "core/UrlEndpoints";
import { fetchDataJson } from "../FetchApi";

class DashboardService {
  async getStats() {
    return fetchDataJson(`${dashboard}/GetStats`, {
      method: "GET",
    });
  }

  async getSeriesAlerts(){    
    return fetchDataJson(`${dashboard}/GetSeriesAlerts`, {
      method: "GET",
    });
  }
}

export default new DashboardService();
