import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import { Auth0Provider } from "react-auth0-spa";
import config from "./auth_config.json";
import { createRoot } from "react-dom/client";
import UserLogService from "core/api/UserLogService";

const onRedirectCallback = (appState) => {
  loginUser(appState.user);
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const loginUser = async (user) => {
  const userLog = { UserEmail: user.email, Status: "Log In", LogDate: new Date() }
  await UserLogService.add(userLog)
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}>
    <App />
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
