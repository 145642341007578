/*eslint-disable*/
import React from "react";
import Table from "../Shared/Table";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import Moment from "react-moment";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { device } from "core/UrlEndpoints";

const List = () => {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Devices</h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Devices</CardTitle>
              </CardHeader>
              <CardBody>
                <Table
                  url={device}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Phone",
                      accessor: "phoneNumber",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
