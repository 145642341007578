import React from "react";
import { useHistory } from "react-router-dom";

export default function StatisticsPanel(props) {
  const history = useHistory();
  return (
    <div className="statistics" onClick={() => { if (props.redirect != "") history.push(`${props.redirect}`) }}>
      <div className="info">
        <div className={`icon icon-${props.iconColor ?? "primary"}`} style={{ fontSize: 20 }}>
          <i className={`now-ui-icons ${props.iconClass}`} />
        </div>
        <h3 className="info-title">{props.stats}</h3>
        <h6 className="stats-title">{props.name}</h6>
      </div>
    </div>
  );
}
