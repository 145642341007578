/*eslint-disable*/
import React, { useEffect, useState } from "react";
import JsonView from "@uiw/react-json-view";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useParams, useHistory, useLocation } from "react-router-dom";
import customerService from "core/api/CustomerService";
import buildingService from "core/api/BuildingService";
import deviceService from "core/api/DeviceService";
import elevatorService from "core/api/ElevatorService";
import alertService from "core/api/AlertService";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import HeaderAddress from "../Shared/HeaderAddress";
import Moment from "react-moment";
import { CrudDropdownEditDelete } from "../Shared/CrudDropdownEditDelete";

function parseJsonOrOther(jsonOrOther) {
  var parsed;

  try {
    parsed = JSON.parse(jsonOrOther);
  } catch (e) {
    return jsonOrOther;
  }

  return parsed;
}

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [additionalData, setAdditionalData] = useState({});
  const [alertData, setAlertData] = useState({
    id: "",
    appName: "",
    status: "",
    dateTime: "",
    deviceId: "",
  });

  const [data, setData] = useState({
    id: "",
    customerId: "",
    buildingId: "",
    name: "",
    phoneNumber: "",
  });

  const [buildingData, setBuildingData] = useState({
    id: "",
    customerId: "",
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  const [customerData, setCustomerData] = useState({
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  const [defaultContact, setDefaultContact] = useState()

  useEffect(() => {
    async function fetchData() {
      const alertInfo = await alertService.get(id);
      const deviceInfo = await elevatorService.get(
        alertInfo.elevatorId ?? alertInfo.deviceId
      );
      const buildingInfo = await buildingService.get(deviceInfo.buildingId);
      const customerInfo = await customerService.get(deviceInfo.customerId);

      setAlertData(alertInfo);
      setData(deviceInfo);
      setBuildingData(buildingInfo);
      setCustomerData(customerInfo);
      setDefaultContact(buildingInfo.contacts.find(contact => contact.defaultContact));
      setLoading(false);

      var additionalDataParsed = {};
      for (let key in alertInfo.additionalData) {
        additionalDataParsed[key] = parseJsonOrOther(
          alertInfo.additionalData[key]
        );
      }

      setAdditionalData(additionalDataParsed);
    }
    fetchData();
  }, [id]);

  if (loading) return <>Loading</>;

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h1 className="title">
              {data.name} - {data.phoneNumber}
            </h1>
            <h2 className="title">
              {customerData.company} / {buildingData.buildingName}
            </h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                {loading && <>loading</>}
                {loading || (
                  <Row>
                    <Col style={{ border: "1px" }}>
                      <HeaderInfoItem title="Company">
                        {customerData.company}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Contact Name">
                        {customerData.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Building">
                        {buildingData.buildingName}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Building Contact Name">
                        {defaultContact.contactName}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Phone Number">
                        {defaultContact.phone}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Email Address">
                        {defaultContact.email}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderAddress {...buildingData} />
                    </Col>
                    <Col xs="auto">
                      <CrudDropdownEditDelete
                        id={id}
                        onDelete={() => {
                          deviceService.delete(id);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Alert: {id}</CardTitle>
              </CardHeader>
              <CardText>
                <ListGroup>
                  <ListGroupItem>Check Name: {alertData.appName}</ListGroupItem>
                  <ListGroupItem>
                    Status: {alertData.status == 1 ? "Working" : "Failed"}
                  </ListGroupItem>
                  <ListGroupItem>
                    Date: <Moment>{alertData.dateTime}</Moment>
                  </ListGroupItem>
                  <ListGroupItem>
                    Call Status: {alertData.callStatus}
                  </ListGroupItem>
                  <ListGroupItem>
                    Response Code: {alertData.responseCode}
                  </ListGroupItem>
                  <ListGroupItem>
                    Additional Data: <JsonView value={additionalData} />
                  </ListGroupItem>
                </ListGroup>
              </CardText>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default View;
