import React from "react";
import { useParams } from "react-router-dom";
import { Field, FieldArray, ErrorMessage } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import * as Yup from "yup";
import { Row, Col, Button, Input } from "reactstrap";
import CrudEdit from "../Crud/Edit";
import service from "core/api/BuildingService.js";
import Address from "../Shared/Address";
import { CustomerSelectorInputComponent } from "./CustomerSelectorInputComponent";
import { CompanySelectorInputComponent } from "./CompanySelectorInputComponent"
import Contact from "views/Shared/Contact";

const BuildingSchema = Yup.object().shape({
  customerId: Yup.string().required("Required"),
  buildingName: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  line2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string()
    .length(2, "Invalid state")
    .uppercase("Must be uppercase")
    .required("Required"),
  postalCode: Yup.string().required("Required"),
  country: Yup.string()
    .length(3, "3 letter country initials")
    .required("Required"),
  contacts: Yup.array().of(
    Yup.object().shape({
      contactName: Yup.string().required("Required"), phone: Yup.string().required("Required"), email: Yup.string().email("invalid email"), defaultContact: Yup.boolean()
    })
  ).test("defaultContact", "Missing default contact", (contacts) => contacts.some(contact => contact.defaultContact))
});

const Edit = () => {
  let { customerId } = useParams();
  const initialData = {
    id: null,
    customerId,
    buildingName: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",

    dispatchTo911: true,
    elevatorCompanyId: null,
    dispatchNotes: "",
    buildingNotes: "",
    contacts: [{
      contactName: "",
      phone: "",
      email: "",
      availableForEmergencyCalls: false,
      sendAlerts: false,
      notifyWhen911Called: false,
      defaultContact: true
    }],
  };

  return (
    <>
      <CrudEdit
        title="Building"
        initData={initialData}
        crudService={service}
        validationSchema={BuildingSchema}>
        <Row>
          <Col>
            <Field
              type="text"
              name="customerId"
              label="Customer"
              component={CustomerSelectorInputComponent}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label style={{ fontSize: 16 }}>
              <Field type="checkbox" name="dispatchTo911" />
              Dispatch to 911 if there is no response from building contact or elevator company contact
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              type="text"
              name="buildingName"
              label="Building Name"
              component={ReactstrapInput}
            />
          </Col>

        </Row>
        <Address component={ReactstrapInput} />

        <Row style={{ paddingTop: 20 }}>
          <Row>
            <Col>
              <Field
                type="text"
                name="elevatorCompanyId"
                label="Elevator Company"
                component={CompanySelectorInputComponent}
              />
            </Col>
          </Row>
        </Row>

        <Row style={{ paddingTop: 20 }}>
          <FieldArray name="contacts">
            {({ form: { values, setFieldValue, errors, touched }, push, remove }) => {
              return (
                <div>
                  {values.contacts.map((contact, index) => (
                    <div key={index} style={{ paddingTop: 20 }}>
                      <Contact component={ReactstrapInput} fieldNamePrefix={`contacts.${index}.`}
                        remove={() => remove(index)}
                        setDefaultContact={(value) => {
                          if (value) { values.contacts.forEach((contact, i) => { setFieldValue(`contacts.${i}.defaultContact`, false) }); }
                          setFieldValue(`contacts.${index}.defaultContact`, value);
                        }} />

                    </div>
                  ))}

                  {typeof errors.contacts === 'string' && touched.contacts && (
                    <ErrorMessage name={`contacts`} component="div" className="invalid-feedback" style={{ display: "block", fontSize: 16 }} />)}

                  <Button color="info" onClick={() => {
                    push({ contactName: "", phone: "", email: "", availableForEmergencyCalls: false, sendAlerts: false, notifyWhen911Called: false, defaultContact: false });
                  }} >Add Contact</Button>


                </div>)
            }}
          </FieldArray>
        </Row>

        <Row style={{ paddingTop: 20 }}>
          <Col>
            <Field
              type="textarea"
              row="5"
              name="dispatchNotes"
              label="Notes for Dispatch"
              component={ReactstrapInput}
            />
          </Col>
          <Col>
            <Field
              type="textarea"
              row="5"
              name="buildingNotes"
              label="Building Notes"
              component={ReactstrapInput}
            />
          </Col>
        </Row>

      </CrudEdit>
    </>
  );
};

export default Edit;
