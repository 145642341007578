import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

export const Image = ({ id, thumbnailUrl, largeUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        {thumbnailUrl && <img src={thumbnailUrl} height={100}></img>}
      </div>
      {isOpen && (
        <Lightbox mainSrc={largeUrl} onCloseRequest={() => setIsOpen(false)} />
      )}
    </>
  );
};
