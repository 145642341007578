/*eslint-disable*/
import React from "react";
import Table from "../Shared/Table";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import Moment from "react-moment";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { elevator } from "core/UrlEndpoints";

const List = () => {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Elevators</h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Elevators</CardTitle>
              </CardHeader>
              <CardBody>
                <Table
                  url={elevator}
                  columns={[
                    {
                      header: "Name",
                      accessorKey: "name",
                    },
                    {
                      header: "Phone",
                      accessorKey: "phoneNumber",
                    },
                    {
                      header: "Is Billing",
                      accessorKey: "isBilling",
                      canFilter: true,
                      filter: 'equals',
                      cell: ({ row }) => (
                        <>{row.original.isBilling ? "True" : "False"}</>
                      ),
                    },
                    {
                      header: "Last Status",
                      accessorKey: "LastStatus",
                    },
                    {
                      header: "Last Update Note",
                      accessorKey: "lastStatusNote",
                    },
                    {
                      header: "Last Status Updated",
                      accessorKey: "lastStatusUpdated",
                      cell: ({ row }) => (
                        <>
                          {row.original.lastStatusUpdated == null ? (
                            ""
                          ) : (
                            <Moment>{row.original.lastStatusUpdated}</Moment>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
