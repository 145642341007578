import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import { Card, CardBody, Row, Col } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { dashboardPanelChart } from "variables/charts.jsx";

import dashboardService from "core/api/DashboardService";
import StatisticsPanel from "./StatisticsPanel";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend)

const Dashboard = () => {
  const [stats, setStats] = useState({});
  const [alertData, setAlertData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const results = await dashboardService.getStats();

      setStats(results);

      const chartResults = await dashboardService.getSeriesAlerts();

      const chartData = dashboardPanelChart({
        labels: chartResults.labels,
        failedData: chartResults.failedData,
        workingData: chartResults.workingData,
      });

      setAlertData(chartData);
    }
    fetchData();
  }, []);

  return (
    <>
      <PanelHeader
        size="lg"
        content={
          alertData && (
            <Line data={alertData.data} options={alertData.options} />
          )
        }
      />

      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-stats card-raised">
              <CardBody>
                <Row>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.customers}
                      name="Customers"
                      iconClass="users_single-02"
                      iconColor="primary"
                      redirect="customers"
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.buildings}
                      name="Buildings"
                      iconClass="las la la-city"
                      iconColor="success"
                      redirect="buildings"
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.elevators}
                      name="Monitoring Points"
                      iconClass="tech_mobile"
                      iconColor="info"
                      redirect=""
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.failedDevices}
                      name="Devices with Issues"
                      iconClass="tech_mobile"
                      iconColor="danger"
                      redirect=""
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.elevatorCompanies}
                      name="Elevator Companies"
                      iconClass="las la-user-friends"
                      iconColor="primary"
                      redirect="elevatorcompanies"
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.incomingCalls}
                      name="Call Center"
                      iconClass="las la-phone-volume"
                      iconColor="success"
                      redirect="callcenter"
                    />
                  </Col>
                  <Col md="3">
                    <StatisticsPanel
                      stats={stats.totalCallReports}
                      name="Call Reports"
                      iconClass="las la-file-alt"
                      iconColor="info"
                      redirect="reports"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
