/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useParams, useHistory, useLocation } from "react-router-dom";
import customerService from "core/api/CustomerService";
import buildingService from "core/api/BuildingService";
import deviceService from "core/api/DeviceService";
import elevatorService from "core/api/ElevatorService";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import HeaderAddress from "../Shared/HeaderAddress";
import { CrudDropdownEditDelete } from "../Shared/CrudDropdownEditDelete";

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: "",
    customerId: "",
    buildingId: "",
    name: "",
    phoneNumber: "",
  });

  const [buildingData, setBuildingData] = useState({
    id: "",
    customerId: "",
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  const [customerData, setCustomerData] = useState({
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  useEffect(() => {
    async function fetchData() {
      const deviceInfo = await deviceService.get(id);
      const elevatorInfo = await elevatorService.get(
        deviceInfo.elevatorId ?? deviceInfo.id
      );
      const buildingInfo = await buildingService.get(elevatorInfo.buildingId);
      const customerInfo = await customerService.get(elevatorInfo.customerId);

      setData(deviceInfo);
      setBuildingData(buildingInfo ?? {});
      setCustomerData(customerInfo ?? {});
      setLoading(false);
    }
    fetchData();
  }, [id]);

  async function MakeTestCall() {
    await deviceService.makeTestCall({ id });
    alert("Test Call Started");
  }

  if (loading) return <>Loading</>;

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h1 className="title">
              {data.name} - {data.phoneNumber}
            </h1>
            <h2 className="title">
              {customerData.company} / {buildingData.buildingName}
            </h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                {loading && <>loading</>}
                {loading || (
                  <Row>
                    <Col style={{ border: "1px" }}>
                      <HeaderInfoItem title="Company">
                        {buildingData.company}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Contact Name">
                        {buildingData.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Building">
                        {buildingData.buildingName}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Building Contact Name">
                        {buildingData.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Phone Number">
                        {buildingData.phone}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Email Address">
                        {buildingData.email}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderAddress {...buildingData} />
                    </Col>
                    <Col xs="auto">
                      <CrudDropdownEditDelete
                        id={id}
                        onDelete={() => {
                          deviceService.delete(id);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default View;
