import { GetToken } from "../react-auth0-spa";
import { baseUrl } from "./UrlEndpoints";

const AbsoluteUrlRegex = new RegExp("^(?:[a-z]+:)?//", "i");

async function fetchData(url, options) {
  const token = await GetToken();

  const fetchOptions = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...options,
  };

  const finalUrl = AbsoluteUrlRegex.test(url) ? url : `${baseUrl}/${url}`;

  return fetch(finalUrl, fetchOptions)
    .then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status === 403) {
          console.log("403 Forbidden");
        } else {
          throw new Error(response);
        }
      }
    })
    .catch((error) => {
      console.log("ERROR", error);
    });
}

async function fetchDataJson(url, options) {
  const response = await fetchData(url, options);

  return response.json();
}

export { fetchData, fetchDataJson };
