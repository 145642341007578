import React, { useState, useEffect, useRef } from "react";
import { baseUrl, filesBucket } from "core/UrlEndpoints";
import { Table } from "reactstrap";

import Upload from "components/UploadPlugin";
import FileBrowserService from "core/api/FileBrowserService";
import { Image } from "./Image";
import confirm from "reactstrap-confirm";
import { EditText } from "react-edit-text";

const ConfirmDeleteDialog = async ({ onDelete }) => {
  if (await confirm()) onDelete();
};

const handleSave = async ({ name, value, previousValue }) => {
  await FileBrowserService.rename(name, previousValue, value);
};

export const FileBrowser = ({ id }) => {
  const layoutRef = useRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await FileBrowserService.getList({
        key: id,
      });
      setFiles(
        response.map((x) => {
          return {
            id: x.fullPath,
            name: x.name,
            thumbnailUrl: x.isFolder
              ? ""
              : `${baseUrl}/${filesBucket}/${x.fullPath}?height=100`,
            largeUrl: x.isFolder
              ? ""
              : `${baseUrl}/${filesBucket}/${x.fullPath}?height=1024`,
          };
        })
      );
    }
    fetchData();
  }, []);
  return (
    <>
      <Table responsive>
        <thead className="text-primary">
          <tr>
            <th>Files</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((prop, key) => {
            return (
              <tr key={key}>
                <td>
                  <Image {...prop}></Image>
                </td>
                <td>
                  <EditText
                    name={id}
                    defaultValue={prop.name}
                    onSave={handleSave}
                    showEditButton
                  />
                </td>
                <td>
                  <button
                    onClick={() =>
                      ConfirmDeleteDialog({
                        onDelete: () => FileBrowserService.delete(prop.id),
                      })
                    }>
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Upload directoryKey={id} />
    </>
  );
};
