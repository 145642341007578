import { elevatorCompany } from "core/UrlEndpoints";
import CrudService from "./CrudService";

class ElevatorCompanyService extends CrudService {
  constructor() {
    super(elevatorCompany);
  }
}

export default new ElevatorCompanyService();
