import React from "react";
import { ErrorMessage, Field } from 'formik';
import { Row, Col, Button } from "reactstrap";

const Contact = ({ component, remove, fieldNamePrefix = "", setDefaultContact }) => {
    return (
        <>
            <Row>
                <Col>
                    <Field type="text" name={`${fieldNamePrefix}contactName`} label="Contact" component={component} />
                    <ErrorMessage name={`${fieldNamePrefix}contactName`} component="div" className="invalid-feedback" style={{ display: "block" }} />
                </Col>
                <Col>
                    <Field type="text" name={`${fieldNamePrefix}phone`} label="Phone Number" component={component} />
                    <ErrorMessage name={`${fieldNamePrefix}phone`} component="div" className="invalid-feedback" style={{ display: "block" }} />
                </Col>
                <Col>
                    <Field type="email" name={`${fieldNamePrefix}email`} label="Email Address" component={component} />
                    <ErrorMessage name={`${fieldNamePrefix}email`} component="div" className="invalid-feedback" style={{ display: "block" }} />
                </Col>
            </Row>
            <Row>  
                <Col md="2">
                    <label style={{ fontSize: 14 }}>
                        <Field type="checkbox" name={`${fieldNamePrefix}defaultContact`}
                            onChange={(e) => {
                                setDefaultContact(e.target.checked)
                            }}
                        />
                        Default contact
                    </label>
                </Col>
                <Col md="3">
                    <label style={{ fontSize: 14 }}>
                        <Field type="checkbox" name={`${fieldNamePrefix}availableForEmergencyCalls`} />
                        Available to answer emergency calls
                    </label>
                </Col>
                <Col md="3">
                    <label style={{ fontSize: 14 }}>
                        <Field type="checkbox" name={`${fieldNamePrefix}sendAlerts`} />
                        Send email alerts when elevator is not working
                    </label>
                </Col>
                <Col md="3">
                    <label style={{ fontSize: 14 }}>
                        <Field type="checkbox" name={`${fieldNamePrefix}notifyWhen911Called`} />
                        Notify by email when 911 called
                    </label>
                </Col>
                <Col>
                    {remove &&
                        <Button color="primary" outline onClick={remove} style={{ marginTop: 0 }} >Remove This Contact</Button>
                    }
                </Col>
            </Row>
        </>
    );
}

export default Contact