/*eslint-disable*/
import React from "react";
import Table from "../Shared/Table";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { building } from "core/UrlEndpoints";

const List = () => {
  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Buildings</h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Buildings</CardTitle>
              </CardHeader>
              <CardBody>
                <Table
                  url={building}
                  columns={[
                    {
                      header: "Customer",
                      accessorKey: "customerName",
                    },
                    {
                      header: "Building",
                      accessorKey: "buildingName",
                    },
                    {
                      header: "Contact Name",
                      id: "contactName",
                      accessorFn: row => { return row.contacts.find(contact => contact.defaultContact)?.contactName }
                    },
                    {
                      header: "Address",
                      accessorKey: "line1",
                      cell: ({ row }) => (
                        <>
                          {row.original.line1} {row.original.line2},{" "}
                          {row.original.city} {row.original.state}
                        </>
                      ),
                    },
                    {
                      header: "Phone",
                      id: "phone",
                      accessorFn: row => { return row.contacts.find(contact => contact.defaultContact)?.phone }
                    },
                    {
                      header: "Send Alerts",
                      accessorKey: "sendAlerts",
                      cell: ({ row }) => (
                        <>{row.original.sendAlerts ? "True" : ""}</>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
