import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

export function CrudDropdownEditDelete({ onDelete }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      {showAlert && (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => setShowAlert(false)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel>
          Are your sure you want to delete this item?
        </SweetAlert>
      )}

      <UncontrolledDropdown>
        <DropdownToggle className="btn-round btn-icon" color="default" outline>
          <i className="now-ui-icons loader_gear" />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={() => history.push(`${pathname}/edit`)}>
            Edit
          </DropdownItem>
          <DropdownItem
            className="text-danger"
            onClick={(e) => {
              setShowAlert(true);
            }}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
