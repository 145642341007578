/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Table from "../Shared/Table";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { useParams, useHistory } from "react-router-dom";
import { building } from "core/UrlEndpoints";
import customerService from "core/api/CustomerService.js";
import HeaderAddress from "../Shared/HeaderAddress";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import { CrudDropdownEditDelete } from "../Shared/CrudDropdownEditDelete";

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    name: "",
    company: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    postalCode: "",
    country: "USA",
    phone: "",
    email: "",
  });

  useEffect(() => {
    async function fetchData() {
      setData(await customerService.get(id));
      setLoading(false);
    }
    if (id) fetchData();
    else setLoading(false);
  }, [id]);

  let title = "Customer";

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">{data.company}</h2>
          </div>
        }
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardBody>
                {loading && <>loading</>}
                {loading || (
                  <Row>
                    <Col>
                      <HeaderInfoItem title="Company">
                        {data.company}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Contact Name">
                        {data.name}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderInfoItem title="Phone Number">
                        {data.phone}
                      </HeaderInfoItem>
                      <HeaderInfoItem title="Email Address">
                        {data.email}
                      </HeaderInfoItem>
                    </Col>
                    <Col>
                      <HeaderAddress {...data} />
                    </Col>
                    <Col xs="auto">
                      <CrudDropdownEditDelete
                        id={id}
                        onDelete={() => {
                          customerService.delete(id);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Buildings</CardTitle>
                <Button
                  color="primary"
                  onClick={() => history.push(`../buildings/${id}/add`)}>
                  Add
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  pathroot="../buildings"
                  url={building}
                  filters={{ CustomerId: id }}
                  columns={[
                    {
                      header: "Building",
                      accessorKey: "buildingName",
                    },
                    {
                      header: "Contact Name",
                      id: "contactName",
                      accessorFn: row => { return row.contacts.find(contact => contact.defaultContact)?.contactName }
                    },
                    {
                      header: "Address",
                      accessorKey: "line1",
                      cell: ({ row }) => (
                        <>
                          {row.original.line1} {row.original.line2},{" "}
                          {row.original.state}, {row.original.city}
                        </>
                      ),
                    },
                    {
                      header: "Phone",
                      id: "phone",
                      accessorFn: row => { return row.contacts.find(contact => contact.defaultContact)?.phone }
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default View;
