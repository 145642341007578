import { device } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchData } from "../FetchApi";

class DeviceService extends CrudService {
  constructor() {
    super(device);
  }

  async makeTestCall({ id }) {
    return fetchData(`MakeTestCallToDevice?id=${id}`, {
      method: "POST",
    });
  }
}

export default new DeviceService();
