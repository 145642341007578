import { building } from "core/UrlEndpoints";
import CrudService from "./CrudService";

class BuildingService extends CrudService {
  constructor() {
    super(building);
  }

}

export default new BuildingService();
