import { fetchData, fetchDataJson } from "../FetchApi";

class CrudService {
  constructor(endpont) {
    this.endpont = endpont;
  }

  async getList(options) {
    return fetchDataJson(`${this.endpont}?page=1&size=50`);
  }

  async get(id) {
    const listModel = await fetchDataJson(`${this.endpont}?size=1&filter[Id]=${id}`);
    return listModel.model[0];
  }

  async add(data) {
    if (data.id) return this.update(data);

    return fetchDataJson(this.endpont, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async update(data) {
    return fetchDataJson(this.endpont, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async delete(id) {
    return fetchData(`${this.endpont}/${id}`, {
      method: "DELETE",
    });
  }
}

export default CrudService;
