import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, CardHeader, CardTitle, CardBody, Input, Table } from "reactstrap";
import UserLogService from "core/api/UserLogService";

export default function UserList({ type, title }) {
    const [activeUsers, setActiveUsers] = useState([]);
    const [date, setDate] = useState(new Date().toISOString().split("T")[0])
    const [time, setTime] = useState(new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }))
    const [tempTime, setTempTime] = useState(time);



    const dateTimeChanged = (event) => {
        if (event.target.id == "date") {
            setDate(event.target.value)
        }
        if (event.target.id == "time") {
            setTempTime(event.target.value)
        }
    }

    const reset = () => {
        var time = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
        setDate(new Date().toISOString().split("T")[0])
        setTime(time)
        setTempTime(time)
    }

    useEffect(() => {
        async function fetchData() {
            let users
            const datetime = new Date(date + "T" + time).toISOString()
            if (type == "active") users = await UserLogService.getActiveUsers(datetime);
            else if (type == "available") users = await UserLogService.getAvailableUsers(datetime);

            setActiveUsers(users.model)
        }

        fetchData();

    }, [date, time]);


    return (
        <>
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">{title}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Input type="date" id="date" value={date} onChange={dateTimeChanged} style={{ width: 250, display: "inline", margin: 10 }} />
                            <Input type="time" id="time" value={time} onChange={dateTimeChanged} onBlur={() => { setTime(tempTime) }} style={{ width: 250, display: "inline", margin: 10 }} />
                            <Button onClick={reset} color="primary">Refresh</Button>

                            <Table striped  >
                                <thead>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            User Email
                                        </th>
                                        <th>
                                            Date Logged In
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeUsers && activeUsers?.map((u, i) =>
                                    (<tr key={i}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            {u.userEmail}
                                        </td>
                                        <td>
                                            {new Date(u.logDate).toLocaleString()}
                                        </td>
                                    </tr>)
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>


    )
}

