import React from "react";


const HeaderInfoItem = ({ title, children }) => {
    return (<div>
        <div style={{
            "fontSize": "smaller",
            "color": "gray"
        }}>{title}</div>
        <div style={{
            "fontWeight": "bold"
        }}>{children}</div>
    </div>);
}

export default HeaderInfoItem