import { notifyContacts } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchData } from "../FetchApi";

class NotifyContactsService extends CrudService {
  constructor() {
    super(notifyContacts);
  }

  async notifyContactsThat911Called(call) {
    return fetchData(`${notifyContacts}`, {
      method: "POST",
      body: JSON.stringify(call),
    });
  }
}

export default new NotifyContactsService();
