import React from "react";
import { Field } from 'formik';
import {
    Row,
    Col
} from "reactstrap";

const Address = ({ component }) => {
    return (<Row>
        <Col>
            <Field type="text" name="line1" label="Address Line 1" component={component} />
        </Col>
        <Col>
            <Field type="text" name="line2" label="Address Line 2" component={component} />
        </Col>
        <Col>
            <Row>
                <Col>
                    <Field type="text" name="city" label="City" component={component} />
                </Col>
                <Col>
                    <Field type="text" name="state" label="State" component={component} />
                </Col>
                <Col>
                    <Field type="text" name="postalCode" label="Zip Code" component={component} />
                </Col>
                <Col>
                    <Field type="text" name="country" label="Country" component={component} />
                </Col>
            </Row>
        </Col>
    </Row>);
}

export default Address