import React from "react";
import { Collapse } from "reactstrap";
import avatar from "assets/img/ryan.jpg";
export class SidebarUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
        };
    }
    render() {
        return (<div className="user">
            <div className="photo">
                <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
                <a href="#" data-toggle="collapse" aria-expanded={this.state.openAvatar} 
                onClick={() => this.setState({
                    openAvatar: !this.state.openAvatar
                })}>
                    <span>
                        Ryan Gosling
                    <b className="caret" />
                    </span>
                </a>
                <Collapse isOpen={this.state.openAvatar}>
                    <ul className="nav">
                        <li>
                            <a href="#palo" onClick={e => e.preventDefault}>
                                <span className="sidebar-mini-icon">MP</span>
                                <span className="sidebar-normal">My Profile</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={e => e.preventDefault}>
                                <span className="sidebar-mini-icon">EP</span>
                                <span className="sidebar-normal">Edit Profile</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={e => e.preventDefault}>
                                <span className="sidebar-mini-icon">S</span>
                                <span className="sidebar-normal">Settings</span>
                            </a>
                        </li>
                    </ul>
                </Collapse>
            </div>
        </div>);
    }
}
