import { useState, useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, CardText, ListGroup, ListGroupItem, Row, Col, Button, Input, Label } from "reactstrap";
import Moment from "react-moment";
import PanelHeader from "components/PanelHeader/PanelHeader";
import HeaderInfoItem from "../Shared/HeaderInfoItem";
import HeaderAddress from "../Shared/HeaderAddress";
import CallService from "core/api/CallService"
import CustomerService from "core/api/CustomerService"
import BuildingService from "core/api/BuildingService"


const View = () => {
    const { id } = useParams();
    const [reportData, setReportData] = useState();
    const [loading, setLoading] = useState(true);
    const [buildingData, setBuildingData] = useState({
        id: "",
        customerId: "",
        name: "",
        company: "",
        line1: "",
        line2: "",
        city: "",
        state: "NY",
        postalCode: "",
        country: "USA",
        phone: "",
        email: "",
    });

    const [customerData, setCustomerData] = useState({
        name: "",
        company: "",
        line1: "",
        line2: "",
        city: "",
        state: "NY",
        postalCode: "",
        country: "USA",
        phone: "",
        email: "",
    });
    const [defaultContact, setDefaultContact] = useState()

    const history = useHistory();

    const ReviewCall = async () => {
        const results = await CallService.update({ ...reportData, status: "reviewed" })
        setReportData(results)
    }

    useEffect(() => {
        async function fetchData() {
            const callData = await CallService.get(id);
            const buildingInfo = await BuildingService.get(callData.buildingId);
            const customerInfo = await CustomerService.get(callData.customerId);

            setReportData(callData);
            setBuildingData(buildingInfo);
            setCustomerData(customerInfo);
            setDefaultContact(buildingInfo.contacts.find(contact => contact.defaultContact))
            setLoading(false);
        }
        fetchData();
    }, [])

    if (loading) return <>Loading</>;
    return (
        <>
            <PanelHeader
                content={
                    <div className="header text-center">
                        <h1 className="title">
                            Elevator Report
                        </h1>
                        <h2 className="title">
                            {reportData.elevatorName}
                        </h2>
                    </div>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col style={{ border: "1px" }}>
                                        <HeaderInfoItem title="Company">
                                            {customerData.company}
                                        </HeaderInfoItem>
                                        <HeaderInfoItem title="Contact Name">
                                            {customerData.name}
                                        </HeaderInfoItem>
                                    </Col>
                                    <Col>
                                        <HeaderInfoItem title="Building">
                                            {buildingData.buildingName}
                                        </HeaderInfoItem>
                                        <HeaderInfoItem title="Building Contact Name">
                                            {defaultContact.contactName}
                                        </HeaderInfoItem>
                                    </Col>
                                    <Col>
                                        <HeaderInfoItem title="Phone Number">
                                            {defaultContact.phone}
                                        </HeaderInfoItem>
                                        <HeaderInfoItem title="Email Address">
                                            {defaultContact.email}
                                        </HeaderInfoItem>
                                    </Col>
                                    <Col>
                                        <HeaderAddress {...buildingData} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Call Information</CardTitle>
                                {/* Once set up user roles - Only show if user is admin */}
                                {reportData.status == "submitted" || reportData.status == "terminated" ? <Button color="primary" onClick={() => ReviewCall()} style={{ fontSize: 14 }} >Mark as Reviewed</Button> : <></>}
                            </CardHeader>
                            <CardBody>
                                <ListGroup >
                                    <ListGroupItem>
                                        <strong>Ref:</strong> {reportData.userEmail}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Elevator:</strong> {reportData.elevatorName}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Elevator Phone Number:</strong> {reportData.elevatorPhoneNumber}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Building:</strong> {reportData.buildingName}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Customer:</strong> {reportData.customerName}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Date Received:</strong> <Moment>{reportData.dateTimeReceived}</Moment>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Date Answered:</strong> <Moment>{reportData.dateTimeAnswered}</Moment>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Date Submitted:</strong> <Moment>{reportData.dateTimeSubmitted}</Moment>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Call Length:</strong> {reportData.callLength} {reportData.callLength == 1 ? "minute" : "minutes"}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <strong>Status:</strong> {reportData.status.toUpperCase()}
                                    </ListGroupItem>
                                    {
                                        reportData.resolution != null &&
                                        <ListGroupItem>
                                            <strong>Resolution:</strong> {reportData.resolution}
                                        </ListGroupItem>
                                    }
                                    {
                                        reportData.status == "terminated" &&
                                        <ListGroupItem>
                                            <strong>Termination Reason:</strong> {reportData.surveyResults.terminationReason}
                                        </ListGroupItem>
                                    }


                                </ListGroup>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Call Questions</CardTitle>
                                <Button onClick={() => { history.push(`../call/${id}`) }}>View Report</Button> 
                            </CardHeader>

                            <CardBody>
                                <ListGroup flush={false}>
                                    {Object.keys(reportData.surveyResults).filter(key => key != "dispatchQuestions").map((key, i) => (
                                        <ListGroupItem key={i}>
                                            <strong>{key.split(/(?=[A-Z])|(?=[0-9])/).map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')}: </strong> {reportData.surveyResults[key]}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >

        </>
    )
}

export default View