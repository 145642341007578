import React from "react";
import Table from "../Shared/Table";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

import Moment from "react-moment";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { call } from "core/UrlEndpoints";
import { useHistory } from "react-router-dom";

const ReportList = () => {
    const history = useHistory();

    return (
        <>
            <PanelHeader
                content={
                    <div className="header text-center">
                        <h2 className="title">Call Reports</h2>
                    </div>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Call Reports</CardTitle>

                            </CardHeader>
                            <CardBody>
                                <Table
                                    url={call}
                                    orderBy={{ field: "DateTimeReceived", desc: true }}
                                    columns={[

                                        {
                                            header: "Elevator",
                                            accessorKey: "elevatorName",
                                        },
                                        {
                                            header: "Phone Number",
                                            accessorKey: "elevatorPhoneNumber",
                                        },
                                        {
                                            header: "Rep",
                                            accessorKey: "userEmail",
                                        },
                                        {
                                            header: "Customer",
                                            accessorKey: "customerName",
                                        },
                                        {
                                            header: "Building",
                                            accessorKey: "buildingName",
                                        }, 
                                        {
                                            header: "Status",
                                            accessorKey: "status",
                                            cell: ({ row }) => (
                                                <>{row.original.status.toUpperCase()}</>
                                            ),
                                        },
                                        {
                                            header: "Date Receieved",
                                            accessorKey: "dateTimeReceived",
                                            accessorFn: row => { return new Date(row.dateTimeReceived) },
                                            filterFn: 'filterDate',
                                        },
                                        {
                                            header: "Date Submitted",
                                            accessorKey: "dateTimeSubmitted",
                                            accessorFn: row => { return row.dateTimeSubmitted != "0001-01-01T00:00:00Z" ? new Date(row.dateTimeSubmitted) : "" },
                                            filterFn: 'filterDate',
                                        },
                                        {
                                            header: "Resolution",
                                            accessorKey: "resolution"
                                        },
                                        {
                                            header: "Call Length",
                                            accessorKey: "callLength",
                                        },

                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ReportList;
