import { userLog } from "core/UrlEndpoints";
import CrudService from "./CrudService";
import { fetchDataJson } from "../FetchApi";

class UserLogService extends CrudService {
    constructor() {
        super(userLog)
    }

    async getActiveUsers(date) {
        return fetchDataJson(`${userLog}/GetActiveUsers?date=${date}`, {
            method: "GET",
        });
    }

    async getAvailableUsers(date) {
        return fetchDataJson(`${userLog}/GetAvailableUsers?date=${date}`, {
            method: "GET",
        });
    }

}


export default new UserLogService();