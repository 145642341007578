import React from "react";
import { useParams } from "react-router-dom";
import { Field } from 'formik';
import { ReactstrapInput } from "reactstrap-formik";
import * as Yup from 'yup';
import {
  Row,
  Col
} from "reactstrap";
import CrudEdit from "../Crud/Edit";
import service from "core/api/DeviceService.js";

const DeviceSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  phoneNumber: Yup.string()
    .required('Required'),
});

const Edit = () => {
  let { customerId, buildingId } = useParams();
  const initialData = {
    id: null,
    customerId,
    buildingId,
    name: '',
    phoneNumber: '',
  };

  return (
    <>
      <CrudEdit
        title="Device"
        initData={initialData}
        crudService={service}
        validationSchema={DeviceSchema}>
        <Row>
          <Col>
            <Field
              type="text"
              name="name"
              label="Device Name"
              component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="phoneNumber"
              label="Phone Number"
              component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="macAddress"
              label="MAC Address"
              component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="model"
              label="Model"
              component={ReactstrapInput} />
          </Col>
        </Row>        
        <Row>
          <Col>
            <Field
              type="text"
              name="sipServer"
              label="SIP Server"
              component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="sipUser"
              label="SIP User"
              component={ReactstrapInput} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              type="text"
              name="sipPassword"
              label="SIP Password"
              component={ReactstrapInput} />
          </Col>
        </Row>
      </CrudEdit>
    </>
  );
}

export default Edit;