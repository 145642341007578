import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const Edit = ({ title, initData, validationSchema, crudService, children }) => {
  let { id } = useParams();
  const history = useHistory();
  const isUpdate = id != null;
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(initData)

  useEffect(() => {
    async function fetchData() {
      setData(await crudService.get(id));
      setLoading(false);
    }
    if (id)
      fetchData()
    else
      setLoading(false);
  }, [id]);

  const submitForm = async (values, { setSubmitting }) => {
    const res = await crudService.add(values);
    setData(res);
    history.push(`../${res.id}`);
    setSubmitting(false); 
  };

  const cancel = () => {

    history.goBack()

  }

  if (loading)
    return <>loading</>;

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{title} - {data.name}</CardTitle>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={data}
                  validationSchema={validationSchema}
                  onSubmit={submitForm}>
                  {({ isSubmitting }) => (
                    <Form className="form-horizontal">
                      {children}
                      <Row>
                        <Col>
                          <Button type="submit" color="primary" disabled={isSubmitting}>
                            {isUpdate ? "Update" : "Create"}
                          </Button>
                          <Button color="primary" onClick={() => cancel()} >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>)}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default Edit;
