import React from "react";
import { createFilter } from "react-select";
import { baseUrl, customer } from "core/UrlEndpoints";
import { fetchDataJson } from "core/FetchApi";
import AsyncSelect from "react-select/async";
import { useState } from "react";
import { Button } from "reactstrap";

export const CustomerSelectorInputComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const [changeCustomer, setChangeCustomer] = useState(false);
  const filterCustomers = async (inputValue) => {
    const responseData = await fetchDataJson(
      `${baseUrl}/${customer}?size=${10}&page=${1}&filter[Company.Name]=${inputValue}*`,
      {
        method: "GET",
      }
    );

    if (responseData.model.length === 0) return null;

    return responseData.model.map((customer) => ({
      value: customer.id,
      label: customer.company,
    }));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(async () => {
      var items = await filterCustomers(inputValue);
      callback(items);
    }, 1000);
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
  };

  if (!changeCustomer)
    return (
      <div>
        <Button type="button" onClick={() => setChangeCustomer(true)}>
          Change Customer
        </Button>
      </div>
    );
  return (
    <div>
      <AsyncSelect
        filterOption={createFilter(filterConfig)}
        cacheOptions
        defaultOptions
        defaultValue={field.value}
        loadOptions={loadOptions}
        {...props}
        onChange={(option) => {
          setFieldValue(field.name, option.value);
        }}
        getOptionLabel={(option) => `${option.label}`}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </div>
  );
};
