import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "components/ReactTable/ReactTable.tsx";
import { useHistory, useLocation } from "react-router-dom";
import { fetchDataJson } from "core/FetchApi";

const Table = ({ columns, pathroot, url, filters, orderBy }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [error, setError] = useState("");

  const callApi = async ({ pageSize, pageIndex }) => {
    try {
      let queryString = `?size=${pageSize}&page=${pageIndex + 1}`;

      for (const key in filters) {
        queryString += `&filter[${key}]=${filters[key]}`;
      }

      if(orderBy){
        queryString += `&orderBy=${orderBy.field}&orderDescending=${orderBy.desc}`;
      }

      const responseData = await fetchDataJson(url + queryString, {
        method: "GET",
      });

      setData(responseData.model);

      setPageCount(responseData.count);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    callApi({ pageSize, pageIndex });
  }, []);

  return (
    <ReactTable
      data={data}
      columns={columns}
      fetchData={fetchData}
      pageCount={pageCount}
      defaultPageSize={100}
      getTrProps={(rowData) => {
        return {
          onClick: (_, handleOriginal) => {
            if (rowData) history.push(`${pathroot ?? pathname}/${rowData.id}`);

            if (handleOriginal) handleOriginal();
          },
        };
      }}
    />
  );
};

export default Table;
