import React, { useState, useEffect } from "react";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Card, CardHeader, CardTitle, CardBody, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import { fetchDataJson } from "core/FetchApi";
import { baseUrl, call, elevator } from "core/UrlEndpoints";
import { useAuth0 } from "react-auth0-spa";
import BuildingService from "core/api/BuildingService";
import CallService from "core/api/CallService";
import UserList from "./UserList";
import ReactTable from "components/ReactTable/ReactTable.tsx";

export default function CallCenter() {
    const [incomingCall, setIncomingCall] = useState(false);
    const [incomingCalls, setIncomingCalls] = useState([])
    const [callTaken, setCallTaken] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();

    const { user } = useAuth0();
    const history = useHistory();

    const takeCall = async (id, status) => {
        if (status == "answered") {
            window.open(`/admin/call/${id}`, "_blank")
        } else {
            const data = await CallService.get(id);
            if (data.status == "received") {
                await CallService.update({ ...data, status: "answered", UserEmail: user.email, DateTimeAnswered: new Date() })
                window.open(`/admin/call/${id}`, "_blank")
            }
            else {
                setCallTaken(true)
            }
        }
    }


    const createCall = async () => {
        const phone = await fetchDataJson(
            `${baseUrl}/${elevator}?filter[PhoneNumber]=${phoneNumber}`,
            {
                method: "GET",
            })

        if (phone.model.length > 0) {
            const elevator = phone.model[0]
            const building = await BuildingService.get(elevator.buildingId)
            const call = {
                elevatorId: elevator.id, elevatorPhoneNumber: phoneNumber, elevatorName: elevator.name, customerId: elevator.customerId, customerName: building.customerName,
                buildingId: elevator.buildingId, buildingName: building.buildingName, dateTimeReceived: new Date(), status: "received"
            }
            await CallService.add(call)
            setIncomingCall(false)

        } else {
            console.log("not found")
        }
    }

    useEffect(() => {
        async function fetchData() {
            const incomingCalls = await CallService.getIncomingCallsForUser(user.email)
            setIncomingCalls(incomingCalls.model)
        }

        fetchData();
        const interval = setInterval(() => {
            fetchData()
        }, 30000)

        return () => { clearInterval(interval) }
    }, []) 


    return (
        <>
            <PanelHeader content={
                <div className="header text-center">
                    <h2 className="title">
                        Call Center
                    </h2>
                </div>}
            />
            <div className="content" style={{ paddingTop: 30 }}>
                <Modal isOpen={incomingCall} centered={true} >
                    <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                        <div className="title">Create Call </div>
                        <div>Enter incoming phone number</div>
                        <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={(e) => setPhoneNumber(e.target.value)}></input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => setIncomingCall(false)} style={{ margin: "auto", display: "block" }} >Cancel</Button>
                        <Button color="primary" onClick={createCall} style={{ margin: "auto", display: "block" }} >Create Call</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={callTaken} centered={true} >
                    <ModalBody style={{ justifyContent: "center", alignContent: "center", textAlign: "center" }} >
                        <div className="title">This Call Was Taken</div>
                        <i className="las la-phone-volume" style={{ fontSize: 50 }}></i>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => setCallTaken(false)} style={{ margin: "auto", display: "block" }} >OK</Button>
                    </ModalFooter>
                </Modal>

                <Button onClick={() => setIncomingCall(true)} style={{ marginRight: "auto", marginLeft: "auto", display: "block", fontSize: 14 }}>Press to simulate an incoming call</Button>

                <Row>
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Incoming Calls</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    data={incomingCalls}
                                    columns={[

                                        {
                                            header: "Phone Number",
                                            accessorKey: "elevatorPhoneNumber",
                                        },
                                        {
                                            header: "Date Received",
                                            accessorKey: "dateTimeReceived",
                                            accessorFn: row => { return new Date(row.dateTimeReceived) },
                                            filterFn: 'filterDate',
                                        },
                                        {
                                            header: "Elevator",
                                            accessorKey: "elevatorName",
                                        },
                                        {
                                            header: "Customer",
                                            accessorKey: "customerName",
                                        },
                                        {
                                            header: "Building",
                                            accessorKey: "buildingName",
                                        },
                                        {
                                            id: "takeCall",
                                            cell: ({ row }) => (
                                                <Button color="primary" style={{ margin: 0 }}
                                                    onClick={(event) => { takeCall(row.original.id, row.original.status); event.stopPropagation() }}
                                                >
                                                    {row.original.status == "answered" ? "Continue Call" : "Take Call"}
                                                </Button>),
                                        }

                                    ]}
                                    fetchData={() => { }}
                                    defaultPageSize={100}
                                    getTrProps={() => { }}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <UserList title="Users Available to Take a Call" type="available" />
                <UserList title="Users Logged In" type="active" />
            </div>
        </>


    )
}

