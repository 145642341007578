import { alert } from "core/UrlEndpoints";
import CrudService from "./CrudService";

class AlertService extends CrudService {
  constructor() {
    super(alert);
  }
}

export default new AlertService();
